import React,{useState,useRef} from 'react'
import 'react-calendar/dist/Calendar.css';
import Layout from '../components/layout'
import moment from "moment";
import "../style/calendar.css";
import axios from 'axios'
import PagNotFound from "./404"

const toChineseNumber = (key) => {
  if(key > 10) return key;
  const ChineseNum = ('一二三四五六七八九十').split('')
  return ChineseNum[key-1]
}

export default ({location}) => {
  console.log(location)
  const {state} = location;

  if(!state) return <PagNotFound/>
  const { name,booking_time,people } = state.result;

  return (
    <Layout>
      <div className="wrap px-6 mx-auto py-8 md:px-10 md:pt-36 md:pb-56 min-h-full-content md:flex">
        <div className="md:w-1/4">
          <h2 className="uppercase font-light text-gray-33 text-20 md:text-4xl">ONLINE BOOKING</h2>
        </div>
        <div className="pt-20 md:flex-1 md:px-20 md:pt-20">
          <div>
            <h3
              className="text-17 text-gray-33 tracking-wide font-medium md:text-2xl border-b border-gray-33 pb-3 inline-block">
              預約賞屋成功
            </h3>
            <p className='whitespace-pre-line text-base md:text-lg mt-5 leading-loose'>
                {name} 先生/小姐您好，感謝您的預約。您預約的賞屋時間為 {moment(booking_time).format('YYYY/MM/DD H:mm')} {toChineseNumber(people)}位。<br/>
                若您需更改時間，請撥打 04 22557888 將有專人為您服務。
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}